export const enTranslations = {
  translation: {
    app: {
      app_name: 'sadeem tech',
    },
    nav: {
      home: 'home',
      about: 'about',
      services: 'services',
      clients: 'clients',
      start_your_project: 'start your project',
      portfolio: 'portfolio',
      news: 'News',
    },
    hero: {
      we_create: 'we create',
      life_easier: 'that make your life easier',
      websites: 'websites',
      applications: 'applications',
      experience: 'experience',
      products: 'products',
    },
    about: {
      title: 'about',
      description:
        'Sadeem Tech Company was established in 2016 with the vision "Technological Solutions for an Easier Life," where it works on innovating technological solutions and providing digital services for various commercial, industrial, and service sectors.',
    },
    labels: {
      contact_us: 'contact us',
      name: 'name',
      company_name: 'company name',
      phone: 'phone number',
      brief: 'brief (optional)',
      project_for: 'project for',
      send: 'send',
      contact_whats: 'contact through whatsapp',
      name_place: 'full name',
      company_place: 'company name',
      phone_place: '9xxxxxxxxx',
      brief_place: 'your message',
      get_service: 'Request the service',
      or_can: 'OR',
    },
    services: {
      label: 'Services',
      description:
        'We work with individuals as well as institutions and companies of all types.',
      cards: {
        0: {
          title: 'software development',
          description:
            'We develop software for various public and private entities, as well as startups, through careful and clear steps.',
        },
        1: {
          title: 'design',
          description:
            "We create designs that make our clients' brands occupy a place in the minds of the audience.",
        },
        2: {
          title: 'digital marketing',
          description:
            "We engage our clients' audience by creating an enhanced virtual presence enriched with static and animated visual content.",
        },
        3: {
          title: 'training and development',
          description:
            "We provide necessary plans tailored to our clients' needs, assisting them in developing their workforce.",
        },
      },
    },
    clients: {
      title: 'they trust us',
      description: 'They are developing and expanding their business with us.',
    },
    apps: {
      title: 'Applications we developed',
      description: 'we have more than 1M download in stores.',
    },
    testi: {
      title: 'Testimonials',
      content: {
        0: {
          img: '/images/testimonials/mazen.jpg',
          name: 'Mazen Al-Bouri',
          title: 'Founder of Eshar Application',
          comment:
            'Everything required was implemented with all professionalism and flexibility by the work team, while adding very effective artistic and technical touches to the application by the team.',
        },
        1: {
          img: '/images/testimonials/tarik_ash.jpg',
          name: 'Tarek Ashkirban',
          title: 'Founder of Pilot Application',
          comment:
            'After researching more than dozens of technical companies and communicating with them personally, I found the best of all companies to be Sadeem Technical. It was a wonderful experience with an integrated team of elite engineers.',
        },
        2: {
          img: '/images/testimonials/salah_eldin.jpg',
          name: 'Salahaddin Al-Bati',
          title:
            'Co-founder of the Roya Foundation for Astronomy and Space Sciences',
          comment:
            'The work experience between Roya Foundation and Sadeem Company is a voluntary experience driven by the incentive to give instilled in both sides, touched by seriousness and continuous interaction from Sadeem to show our work in the best way.',
        },
        3: {
          img: '/images/testimonials/abd_alhaq.jpg',
          name: 'Abdelhak Faiz',
          title:
            'In charge of managing projects at the Civil Society Commission',
          comment:
            'We worked together on a Rawabet project during difficult times that the country was going through, and Sadeem proved during this experience that it has a team that works with love, passion, and indomitable courage.',
        },
        4: {
          img: '/images/testimonials/habib.jpg',
          name: 'Habib Nino',
          title: 'Founder of Zeeh',
          comment:
            'After experience and communication with many companies, I can say that Sadeem is the best in Libya in developing mobile applications. I liked the efficiency of the team, their understanding of their work, and the quality that my product showed.',
        },
      },
    },
    footer: {
      links: 'links',
      social_links: 'social links',
      about:
        'A company that works to innovate technical solutions and provide advanced systems for various commercial, industrial and service sectors.',
    },
    alert: {
      check_phone: 'Check your phone',
      message_delivers: 'thanks for contacting with us',
      invalid_phone: 'enter an invalid phone number',
      required_name_phone: 'enter company name and phone number please',
    },
    titles: {
      our_news: 'Our News',
    },
  },
};
