import { enqueueSnackbar } from 'notistack';

export const fireAlert = (msg, variant) => {
  enqueueSnackbar(msg, {
    variant: variant || 'success',
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'top',
    },
  });
};
