import React from 'react';
import Navbar from '../navbar/Navbar';
import { Outlet } from 'react-router';
import Footer from '../footer/Footer';
import { SnackbarProvider } from 'notistack';

const RootLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      <SnackbarProvider />
    </>
  );
};

export default RootLayout;
