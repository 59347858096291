import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './testiSlide.module.css';
const TestiSlide = ({ index }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.slide}>
      <p className={styles.comment}>{t(`testi.content.${index}.comment`)}</p>
      <img
        src={t(`testi.content.${index}.img`) || `/images/sadeem_logo.png`}
        alt={index}
        width="64px"
        height="64px"
      />
      <p className={styles.name}>{t(`testi.content.${index}.name`)}</p>
      <p className={styles.title}>{t(`testi.content.${index}.title`)}</p>
    </div>
  );
};

export default TestiSlide;
