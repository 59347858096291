import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
const AnimatedLoopText = ({ words }) => {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      let next = index + 1;
      if (next === words.length) {
        next = 0;
      }
      setIndex(next);
    }, 3 * 1000);
  }, [index, setIndex]);

  return (
    <motion.span
      key={words[index]}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, width: 'fit-content' }}
      exist={{ y: -20, opacity: 0 }}
      transition={{ ease: 'easeInOut' }}
      style={{ position: 'relative', width: 'fit-content' }}
    >
      {t(words[index])}
    </motion.span>
  );
};

export default AnimatedLoopText;
