import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from './InputField';
import styles from './contactUs.module.css';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { sendMessage } from '../../apiCall/sendMessage';
import { fireAlert } from '../../utils/alerts/fireAlert';

const ContactUs = ({ closeForm }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    name: '',
    phone: '',
    summary: '',
  });
  const resetValues = () => {
    setValues({
      name: '',
      phone: '',
      summary: '',
    });
  };
  const sendRequest = (e) => {
    const phoneRegex = /^9[1-3][0-9]{7}$/;
    e.preventDefault();
    if (!values.phone) {
      fireAlert(t('alert.required_name_phone'), 'error');
    } else if (!phoneRegex.test(values.phone)) {
      fireAlert(t('alert.invalid_phone'), 'error');
    } else {
      sendMessage(values)
        .then((res) => res.json())
        .then((data) => {
          if (data.id) {
            resetValues();
            closeForm();
            fireAlert(t('alert.message_delivers'), 'success');
          }
        })
        .catch((err) => fireAlert(err.message, 'error'));
    }
  };

  return (
    <div className={styles.contact_us}>
      <h2>{t('labels.contact_us')}</h2>
      <form className={styles.contact_form} onSubmit={sendRequest}>
        <InputField
          name="name"
          id="name"
          type="text"
          label={t('labels.name')}
          placeholder={t('labels.name_place')}
          value={values.name}
          setValues={setValues}
        />
        <InputField
          name="phone"
          id="phone"
          label={t('labels.phone')}
          type="tel"
          placeholder={t('labels.phone_place')}
          value={values.phone}
          setValues={setValues}
        />
        <InputField
          name="summary"
          id="summary"
          type="textarea"
          label={t('labels.brief')}
          placeholder={t('labels.brief_place')}
          value={values.summary}
          setValues={setValues}
        />

        <button type="submit">{t('labels.send')}</button>
        <p className={styles.actions_divider}>{t('labels.or_can')}</p>
        <a href="https://wa.me/218910024433" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 464 488"
          >
            <path
              fill="currentColor"
              d="M462 228q0 93-66 159t-160 66q-56 0-109-28L2 464l40-120q-32-54-32-116q0-93 66-158.5T236 4t160 65.5T462 228zM236 39q-79 0-134.5 55.5T46 228q0 62 36 111l-24 70l74-23q49 31 104 31q79 0 134.5-55.5T426 228T370.5 94.5T236 39zm114 241q-1-1-10-7q-3-1-19-8.5t-19-8.5q-9-3-13 2q-1 3-4.5 7.5t-7.5 9t-5 5.5q-4 6-12 1q-34-17-45-27q-7-7-13.5-15t-12-15t-5.5-8q-3-7 3-11q4-6 8-10l6-9q2-5-1-10q-4-13-17-41q-3-9-12-9h-11q-9 0-15 7q-19 19-19 45q0 24 22 57l2 3q2 3 4.5 6.5t7 9t9 10.5t10.5 11.5t13 12.5t14.5 11.5t16.5 10t18 8.5q16 6 27.5 10t18 5t9.5 1t7-1t5-1q9-1 21.5-9t15.5-17q8-21 3-26z"
            />
          </svg>
          {t('labels.contact_whats')}
        </a>
      </form>
    </div>
  );
};

export default ContactUs;
