import React, { useState, useEffect } from 'react';
import styles from './navbar.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../utils/i18n/i18n';
import DialogWrapper from '../dialog/DialogWrapper';
import ContactUs from '../forms/ContactUs';
import DownloadIcon from '../../components/icons/DownloadIcon';
import { Link, useNavigate, useLocation } from 'react-router-dom';
const Navbar = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clickLink = (to) => {
    if (showSideNav) {
      setShowSideNav(false);
    }
    navigate(to);
  };
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className={styles.navbar}>
      <div className={`container ${styles.navbar_container}`}>
        <img
          src="/images/sadeem_logo.webp"
          alt="sadeem_tech"
          width="60px"
          height="60px"
        />
        <ul
          className={`${styles.navbar_items} ${showSideNav ? styles.show : ''}`}
        >
          <div className={styles.main_links}>
            <li>
              <button onClick={() => clickLink('/#')}>{t('nav.home')}</button>
            </li>
            <li>
              <button onClick={() => clickLink('/#about')}>
                {t('nav.about')}
              </button>
            </li>
            <li>
              <button onClick={() => clickLink('/#services')}>
                {t('nav.services')}
              </button>
            </li>
            <li>
              <button onClick={() => clickLink('/#clients')}>
                {t('nav.clients')}
              </button>
            </li>
            <li>
              <button onClick={() => clickLink('/news')}>
                {t('nav.news')}
              </button>
            </li>
            {i18n.language === 'ar' ? (
              <button onClick={() => i18n.changeLanguage('en')}>English</button>
            ) : (
              <button onClick={() => i18n.changeLanguage('ar')}>العربية</button>
            )}
          </div>
          <div className={styles.main_actions}>
            {
              //
              // <a
              //   className={styles.action}
              //   href="/files/Sadeem Company Profile Draft.pdf"
              //   download={true}
              // >
              //   <DownloadIcon width="16px" height="16px" />
              //   {t('nav.portfolio')}
              // </a>
            }
            <button
              className={styles.action}
              onClick={() => setOpenContactUs(true)}
            >
              <span>{t('nav.start_your_project')}</span>
            </button>
          </div>
        </ul>
        <div
          className={`${styles.navbar_menu_icon} ${
            showSideNav ? styles.x_icon : ''
          }`}
          onClick={() => setShowSideNav((prev) => !prev)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <DialogWrapper open={openContactUs} setOpen={setOpenContactUs}>
        <ContactUs closeForm={() => setOpenContactUs(false)} />
      </DialogWrapper>
    </div>
  );
};

export default Navbar;
