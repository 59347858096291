import React, { useRef } from 'react';
import styles from './about.module.css';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import i18n from '../../utils/i18n/i18n';
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.about} id="about">
      <div className={`container ${styles.about_container}`}>
        <AnimatePresence>
          <div>
            <h2 className="section_title">{t('about.title')}</h2>
            <p>
              {t('about.description')
                .split(' ')
                .map((word, index) => (
                  <motion.span
                    key={`${index}`}
                    initial={{ opacity: 0 }}
                    whileInView={{
                      opacity: 1,
                      transition: { delay: index * 0.06 },
                    }}
                    viewport={{ once: true }}
                  >
                    {word}{' '}
                  </motion.span>
                ))}
            </p>
          </div>
          <div className={styles.img_wrapper} dir={i18n.dir()}>
            <img src="/images/about_img.svg" height="400px" />
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AboutUs;
