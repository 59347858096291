import styles from './apps.module.css';
import i18n from '../../utils/i18n/i18n';
import AutoScrollInfinityCarsouleWrapper from '../carousel/AutoScrollInfinityCarsouleWrapper';

const slides = [
  '/images/apps/alofoq.png',
  '/images/apps/arkenu.png',
  '/images/apps/beta.png',
  '/images/apps/flexnet.png',
  '/images/apps/gtech.png',
  '/images/apps/lahtha_2.png',
  '/images/apps/qnet.png',
  '/images/apps/tebra.png',
];
const AppsCarousel = () => {
  return (
    <AutoScrollInfinityCarsouleWrapper
      dir={i18n.dir() === 'rtl' ? 'ltr' : 'rtl'}
    >
      <>
        {slides.map((slide, index) => (
          <div
            className={`embla__slide ${styles.app_slide}`}
            key={`${index}-${slide}`}
          >
            <img
              src={slide}
              height="100px"
              alt="logo"
              className={styles.app_logo}
              style={{ mixBlendMode: 'multiply' }}
            />
          </div>
        ))}
      </>
    </AutoScrollInfinityCarsouleWrapper>
  );
};

export default AppsCarousel;
