import { useState, useEffect, lazy, Suspense } from 'react';
// react router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// components
import RootLayout from './components/layout/RootLayout';
import CircleCompleteLoader from './components/loaders/CircleCompleteLoader';
// pages
import Home from './pages/home/Home';
// i18n
import i18n from './utils/i18n/i18n';
// GA
import ReactGA from 'react-ga4';
// lazy pages
const News = lazy(() => import('./pages/news/News'));
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'news',
        element: (
          <Suspense
            fallback={
              <div
                style={{
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircleCompleteLoader />
              </div>
            }
          >
            <News />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

  const [languageDir, setLanguageDir] = useState(i18n.dir());

  useEffect(() => {
    const languageChangeHandler = () => {
      setLanguageDir(i18n.dir());
    };

    i18n.on('languageChanged', languageChangeHandler);

    return () => {
      i18n.off('languageChanged', languageChangeHandler);
    };
  }, []);
  return (
    <div className="App" dir={languageDir} style={{ fontFamily: 'Tajawal' }}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
