import React from 'react';
import styles from './testimonials.module.css';
import { useTranslation } from 'react-i18next';
import TestiCarousel from './TestiCarousel';
const Testimonials = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.testi}>
      <div className="container">
        <h2 className="section_title">{t('testi.title')}</h2>
        <TestiCarousel />
      </div>
    </div>
  );
};

export default Testimonials;
