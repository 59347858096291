export const arTranslations = {
  translation: {
    app: {
      app_name: 'سديم تك',
    },
    nav: {
      home: 'الرئيسية',
      about: 'من نحن',
      services: 'خدماتنا',
      clients: 'عملاؤنا',
      start_your_project: 'ابدا مشروعك',
      portfolio: 'الملف التعريفي',
      news: 'اخبارنا',
    },
    hero: {
      we_create: 'نصنع',
      life_easier: 'لحياة اسهل',
      websites: 'مواقع',
      applications: 'تطبيقات',
      experience: 'تجربة',
      products: 'منتجات ',
    },
    about: {
      title: 'لنتعرف اكثر',
      description:
        'تأسست شركة سديم التقنية في سنة 2016 على الرؤية "حلول تقنية لحياة أسهل" حيث تعمل على ابتكار حلول تقنية وتوفير خدمات رقمية لمختلف القطاعات التجارية والصناعية والخدمية.',
    },
    labels: {
      contact_us: 'تواصل معنا',
      name: 'الاسم',
      company_name: 'اسم الشركة/ جهة العمل',
      send: 'ارسال',
      phone: 'رقم الهاتف',
      contact_whats: 'التواصل عبر الواتساب',
      name_place: 'الاسم ',
      company_place: 'اسم الشركة',
      phone_place: '9xxxxxxxxx',
      brief: 'نبذة عن المشروع',
      brief_place: 'رسالتك.....',
      get_service: 'اطلب الخدمة',
      or_can: 'او يمكنك',
    },
    services: {
      label: 'خدماتنا',
      description: 'نعمل سواء مع الافراد أو المؤسسات والشركات بمختلف انواعها',
      cards: {
        0: {
          title: 'تطوير البرمجيات',
          description:
            'نصنع برمجيات لمختلف الجهات العامة والخاصة والمشاريع الناشئة عبر خطوات مدروسة وواضحة.',
        },
        1: {
          title: 'التصميم',
          description:
            'نصنع تصميمات تجعل العلامة التجارية لزبئاننا تحتل مكانة في أذهان الجمهور.',
        },
        2: {
          title: 'التسويق الالكتروني',
          description:
            'نخاطب جمهور زبائننا من خلال صناعة تواجد إفتراضي معزز بالمحتوى البصري الثابت والمتحرك.',
        },
        3: {
          title: 'التدريب و التطوير',
          description:
            'نوفر الخطط اللازمة وبناءً على حاجات زبائننا، بما يساعدهم في تطوير كادرهم الوظيفي',
        },
      },
    },
    clients: {
      title: 'يثقون بنا',
      description: 'يقومون بتطوير وتنمية أعمالهم معنا',
    },
    apps: {
      title: 'تطبيقات قمنا بتطويرها',
      description: 'لدينا اكثر من مليون تحميل علي المتاجر',
    },
    testi: {
      title: 'قالو عنا',
      content: {
        0: {
          img: '/images/testimonials/mazen.jpg',
          name: 'مازن البوري',
          title: 'مؤسس تطبيق اشعار',
          comment:
            'تم تنفيذ كل المطلوب بكل احترافيه ومرونة من فريق العمل مع اضافة لمسات فنيه وتقنيه فعالة جدا للتطبيق من قبل الفريق.',
        },
        1: {
          img: '/images/testimonials/tarik_ash.jpg',
          name: 'طارق اشكيربان',
          title: 'مؤسس تطبيق بايلوت',
          comment:
            'بعد بحث أكثر من عشرات الشركات التقنية و التواصل معهم بشكل شخصي ، الا اني وجدت الأفضل من جميع الشركات هي شركة السديم التقنية ، تجربة كانت رائعة مع فريق مُتكامل من نخبة من المهندسين . ',
        },
        2: {
          img: '/images/testimonials/salah_eldin.jpg',
          name: 'صلاح الدين البطي',
          title: 'مؤسس مشارك في مؤسسة رؤية لعلوم الفلك والفضاء',
          comment:
            'تجربة العمل بين مؤسسة رؤية و شركة سديم هي تجرية تطوعية مدفوعة بحافز العطاء المغروس في الجانبين، لمست جدية و تفاعل مستمر من سديم لإظهار عملنا بأحسن صورة.',
        },
        3: {
          img: '/images/testimonials/abd_alhaq.jpg',
          name: 'عبد الحق فائز',
          title: 'المكلف بإدارة مشروعات مفوضية المجتمع المدني',
          comment:
            'قمنا بالعمل سوياً على مشروع روابط في أوقات صعبة كانت تمر بها البلاد وأثبتت سديم خلال هذه التجربة أنها تملك فريق يعمل بحب وشغف وشجاعة لا تقهر',
        },
        4: {
          img: '/images/testimonials/habib.jpg',
          name: 'حبيب نينو',
          title: 'مؤسس Zeeh',
          comment:
            'بعد التجربة والتواصل مع العديد من الشركات أستطيع أن أقول إن سديم الأفضل في ليبيا في تطوير تطبيقات الهاتف، أعجبتني كفاءة الفريق وفهمهم لعملهم والجودة التي ظهر بها منتجي ',
        },
      },
    },
    footer: {
      links: 'روابط',
      social_links: 'وسائل التواصل',
      about:
        'شركة تعمل على ابتكار حلول تقنية وتوفير انظمة متطورة لمختلف القطاعات التجارية والصناعية والخدمية ',
    },
    alert: {
      check_phone: 'تم وصول الرسالة اليك',
      message_delivers: 'تم استلام رسالتك سوف يتم التواصل معك في اقرب وقت ',
      invalid_phone: 'ادخل رقم الهاتف الصحيح يبدا بكود الدولة',
      required_name_phone: "'من فضلك ادخل رقم الهاتف و اسم المنظمة'",
    },
    titles: {
      our_news: 'اخبارنا',
    },
  },
};
