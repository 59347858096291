import React from 'react';
import styles from './heroSection.module.css';
import { useTranslation } from 'react-i18next';
import AnimatedLoopText from './AnimatedLoopText';
import { AnimatePresence } from 'framer-motion';
import i18n from '../../utils/i18n/i18n';

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.hero}`}>
      <div className={`container ${styles.hero_container} `}>
        <AnimatePresence>
          <h1>
            <span>{t('hero.we_create')}</span>
            <AnimatedLoopText
              words={[
                'hero.websites',
                'hero.products',
                'hero.experience',
                'hero.applications',
              ]}
            />
            <span>{t('hero.life_easier')}</span>
          </h1>
        </AnimatePresence>
        <div className={styles.hero_img_wrapper}>
          <img
            src="/images/tech_company_with_bg.svg"
            alt="hero_img"
            height="400px"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
