import { enqueueSnackbar } from 'notistack';

export const sendMessage = async (body, setValues) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/feedbacks`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};
