import React, { useState } from 'react';
// motion
import { motion } from 'framer-motion';
// i18n
import { useTranslation } from 'react-i18next';
// styles
import styles from './services.module.css';
import UnknownIcons from '../icons/UnknownIcons';
import DevelopmentIcon from '../icons/DevelopmentIcon';
import TrainingIcon from '../icons/TrainingIcon';
import MarketingIcon from '../icons/MarketingIcon';
import DesignIcon from '../icons/DesignIcon';
import DialogWrapper from '../dialog/DialogWrapper';
import ContactUs from '../forms/ContactUs';
const services = [
  {
    icon: <DevelopmentIcon />,
  },
  {
    icon: <DesignIcon />,
  },
  {
    icon: <MarketingIcon />,
  },
  {
    icon: <TrainingIcon />,
  },
];
const moveCard = {
  initial: {
    x: -500,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
  },
};
const Services = () => {
  const { t } = useTranslation();
  const [openContactUs, setOpenContactUs] = useState(false);
  return (
    <div className={styles.services} id="services">
      <div className={`container`}>
        <h2 className={`section_title`}>{t('services.label')}</h2>
        <p className={styles.description}>{t('services.description')}</p>
        <div className={styles.cards_wrapper}>
          {services.map((serv, index) => (
            <motion.div
              key={index}
              className={styles.service_card}
              initial={{
                opacity: 0,
                x: -100 * index,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              variants={moveCard}
              transition={{
                delay: index * 0.2,
                type: 'tween',
              }}
              viewport={{ once: true, amount: 0.1 }}
            >
              <div>{serv.icon}</div>
              <h3>{t(`services.cards.${index}.title`)}</h3>
              <p>{t(`services.cards.${index}.description`)}</p>
              <button
                className={styles.get_service_btn}
                onClick={() => setOpenContactUs(true)}
              >
                {t('labels.get_service')}
              </button>
            </motion.div>
          ))}
        </div>
      </div>
      <DialogWrapper open={openContactUs} setOpen={setOpenContactUs}>
        <ContactUs closeForm={() => setOpenContactUs(false)} />
      </DialogWrapper>
    </div>
  );
};

export default Services;
