import React from 'react';
// components
import HeroSection from '../../components/hero-section/HeroSection';
import AboutUs from '../../components/about/AboutUs';
import Services from '../../components/services/Services';
import Clients from '../../components/clients/Clients';
import Testimonials from '../../components/testimonials/Testimonials';
import Apps from '../../components/apps/Apps';
import ContactUsFloating from '../../components/buttons/ContactUsFloating';

const Home = () => {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <Services />
      <Clients />
      {/* <Apps /> */}
      <Testimonials />
      <ContactUsFloating />
    </>
  );
};

export default Home;
