import React from 'react';

const ContactIcon = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="ic-contact-chat">
        <path
          style={{
            fill: 'none',
            stroke: 'var(--primary-1)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
          }}
          class="cls-1"
          d="M4,3H14a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H11L8,16,5,13H4a2,2,0,0,1-2-2V5A2,2,0,0,1,4,3Z"
        />

        <path
          style={{
            fill: 'none',
            stroke: 'var(--primary-1)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
          }}
          class="cls-1"
          d="M16,8h4a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H18l-2,3-3-3H10a2,2,0,0,1-2-2H8"
        />
      </g>
    </svg>
  );
};

export default ContactIcon;
