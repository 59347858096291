import styles from './clients.module.css';
import i18n from '../../utils/i18n/i18n';
import AutoScrollInfinityCarsouleWrapper from '../carousel/AutoScrollInfinityCarsouleWrapper';
const slides = [
  '/images/companies/bank_commerce.png',
  '/images/companies/central_bank.png',
  '/images/companies/lybia_hosbital.png',
  '/images/companies/mansoura.png',
  '/images/companies/llc.png',
  '/images/companies/roya.png',
  '/images/companies/nation_oil.png',
  '/images/companies/tatweer.png',
];

const ClientsCarousel = () => {
  return (
    <AutoScrollInfinityCarsouleWrapper dir={i18n.dir() || 'rtl'}>
      <>
        {slides.map((slide, index) => (
          <div
            className={`embla__slide ${styles.client_slide}`}
            key={`${index}-${slide}`}
          >
            <img
              src={slide}
              height="100px"
              alt="logo"
              className={styles.client_logo}
              style={{ mixBlendMode: 'multiply' }}
            />
          </div>
        ))}
      </>
    </AutoScrollInfinityCarsouleWrapper>
  );
};

export default ClientsCarousel;
