import React from 'react';
import styles from './dialog.module.css';
import { AnimatePresence, motion } from 'framer-motion';
const DialogWrapper = ({ open, setOpen, children }) => {
  const handleCloseDialog = (e) => {
    if (!e.target.classList.contains('dialog_content')) setOpen(false);
  };
  return open ? (
    <AnimatePresence>
      <motion.div
        className={styles.dialog}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2, type: 'tween' } }}
        exit={{ opacity: 0 }}
      >
        <div className={styles.overlay} onClick={handleCloseDialog}></div>
        <div className={`${styles.dialog_content} dialog_content`}>
          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  ) : (
    <></>
  );
};

export default DialogWrapper;
