import React, { useEffect } from 'react';
import styles from './inputField.module.css';
const InputField = ({
  id,
  name,
  type,
  label,
  placeholder,
  value,
  setValues,
}) => {
  return (
    <div className={styles.inputField}>
      <label htmlFor={id}>{label}</label>
      {type === 'textarea' ? (
        <textarea
          name={name}
          id={id}
          placeholder={placeholder}
          rows={5}
          value={value}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [name]: e.target.value }))
          }
        />
      ) : type === 'tel' ? (
        <InputPhone
          name={name}
          id={id}
          placeholder={placeholder}
          rows={5}
          value={value}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [name]: e.target.value }))
          }
        />
      ) : (
        <>
          <input
            type={type}
            name={name}
            id={id}
            placeholder={placeholder || label}
            value={value}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, [name]: e.target.value }))
            }
          />
        </>
      )}
    </div>
  );
};

export default InputField;

const InputPhone = ({
  type,
  name,
  id,
  placeholder,
  label,
  value,
  onChange,
}) => {
  return (
    <div className={styles.phone_field}>
      <div className={styles.country_code}>
        <span>218</span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 36 36"
        >
          <path
            fill="#141414"
            d="M0 24h36V12H0v12zm21.25-8.298l1.032 1.42l1.67-.542L22.92 18l1.032 1.42l-1.67-.542l-1.032 1.42v-1.756L19.58 18l1.67-.543v-1.755zM18 14.75c.902 0 1.718.368 2.307.961a2.808 2.808 0 0 0-1.649-.535a2.824 2.824 0 1 0 1.649 5.113A3.25 3.25 0 1 1 18 14.75z"
          />
          <path
            fill="#E70013"
            d="M32 5H4a4 4 0 0 0-4 4v3h36V9a4 4 0 0 0-4-4z"
          />
          <path
            fill="#239E46"
            d="M32 31H4a4 4 0 0 1-4-4v-3h36v3a4 4 0 0 1-4 4z"
          />
          <path
            fill="#FFF"
            d="M18 21.25c.902 0 1.718-.368 2.307-.961a2.808 2.808 0 0 1-1.649.535a2.824 2.824 0 1 1 1.649-5.113A3.25 3.25 0 1 0 18 21.25z"
          />
          <path
            fill="#FFF"
            d="M21.25 18.542v1.756l1.032-1.42l1.67.542L22.92 18l1.032-1.42l-1.67.542l-1.032-1.42v1.755L19.58 18z"
          />
        </svg>
      </div>
      <input
        dir="ltr"
        type={type}
        name={name}
        id={id}
        placeholder={placeholder || label}
        values={value}
        onChange={onChange}
      />
    </div>
  );
};
