import React, { useCallback, useEffect, useState } from 'react';
// components
import { DotButton, useDotButton } from './TestiCarouseDot';
import TestiSlide from './TestiSlide';
// embla
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from './TestiCarouseArrowButton';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
// i18n
import i18n from '../../utils/i18n/i18n';
import { useTranslation } from 'react-i18next';
// styles
import styles from './testimonials.module.css';

const TestiCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      direction: i18n.dir(),
      loop: true,
    },
    [Autoplay({ playOnInit: true, delay: 5000 })]
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);
  const { t } = useTranslation();
  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    setIsPlaying(autoplay.isPlaying());
    emblaApi.on('reInit', () => setIsPlaying(autoplay.isPlaying()));
  }, [emblaApi]);
  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {[0, 1, 2, 3, 4].map((index) => (
            <div
              className={`embla__slide`}
              key={index}
              style={{ minWidth: '100%' }}
            >
              <TestiSlide index={index} />
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
};

export default TestiCarousel;
