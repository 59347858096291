import React from 'react';
import ClientsCarousel from './ClientsCarousel';
// styles
import styles from './clients.module.css';
import { useTranslation } from 'react-i18next';
import AppsCarousel from '../apps/AppsCarousel';
const Clients = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.clients} id="clients">
      <div className="container">
        <h2 className="section_title">{t('clients.title')}</h2>
        <p>{t('clients.description')}</p>
        <ClientsCarousel />
        <AppsCarousel />
      </div>
    </div>
  );
};

export default Clients;
