import React from 'react';

const DevelopmentIcon = () => {
  return (
    <svg
      fill="var(--primary)"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48px"
      height="48px"
      viewBox="0 0 72 72"
      color="var(--primary)"
      enableBackground="new 0 0 72 72"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M61.568,4H10.432C6.604,4,3.5,7.104,3.5,10.932v50.136c0,3.828,3.104,6.932,6.932,6.933h51.136
		c3.828,0,6.932-3.104,6.932-6.933h0.001V10.932C68.501,7.104,65.396,4,61.568,4z M10.432,8h51.136c1.619,0,2.932,1.313,2.932,2.932
		h0.001V17H7.5v-6.068C7.5,9.313,8.813,8,10.432,8z M61.568,64H10.432C8.813,64,7.5,62.688,7.5,61.068V19h57.001v42.068
		C64.501,62.688,63.188,64,61.568,64z"
          color="currentcolor"
        />
        <path d="M11.119,14h1c0.552,0,1-0.447,1-1s-0.448-1-1-1h-1c-0.553,0-1,0.447-1,1S10.566,14,11.119,14z" />
        <path d="M18.119,14h1c0.552,0,1-0.447,1-1s-0.448-1-1-1h-1c-0.553,0-1,0.447-1,1S17.566,14,18.119,14z" />
        <path d="M25.119,14h1c0.552,0,1-0.447,1-1s-0.448-1-1-1h-1c-0.553,0-1,0.447-1,1S24.566,14,25.119,14z" />
        <path
          d="M17.405,42.168l5.137-6.34c0.348-0.428,0.283-1.058-0.147-1.406c-0.428-0.346-1.058-0.281-1.406,0.147l-5.647,6.97
		c-0.297,0.367-0.297,0.893,0,1.26l9.167,11.313c0.197,0.242,0.486,0.369,0.777,0.369c0.221,0,0.443-0.071,0.628-0.223
		c0.428-0.347,0.495-0.977,0.147-1.406L17.405,42.168z"
        />
        <path
          d="M24.509,30.227l-0.914,1.127c-0.348,0.428-0.282,1.058,0.147,1.406c0.186,0.151,0.408,0.224,0.629,0.224
		c0.291,0,0.58-0.127,0.777-0.37l0.914-1.127c0.348-0.428,0.281-1.058-0.147-1.406C25.487,29.733,24.858,29.798,24.509,30.227z"
        />
        <path
          d="M47.729,30.226c-0.348-0.428-0.979-0.494-1.405-0.147c-0.429,0.347-0.495,0.977-0.147,1.407l8.656,10.682l-8.656,10.684
		c-0.348,0.43-0.283,1.059,0.147,1.406c0.185,0.151,0.407,0.223,0.629,0.223c0.291,0,0.579-0.127,0.776-0.369l9.166-11.313
		c0.299-0.367,0.299-0.893,0-1.26L47.729,30.226z"
        />
        <path
          d="M40.343,36.226l-9.167,11.313c-0.348,0.43-0.282,1.059,0.147,1.406c0.186,0.15,0.408,0.223,0.629,0.223
		c0.291,0,0.58-0.126,0.777-0.369l9.167-11.313c0.348-0.43,0.281-1.06-0.147-1.408C41.32,35.732,40.691,35.798,40.343,36.226z"
        />
      </g>
    </svg>
  );
};

export default DevelopmentIcon;
