import React, { useState } from 'react';
import styles from './contactUsFloating.module.css';
import ContactIcon from '../icons/ContactIcon';
import DialogWrapper from '../dialog/DialogWrapper';
import ContactUs from '../forms/ContactUs';
const ContactUsFloating = () => {
  const [openContactUs, setOpenContactUs] = useState(false);

  return (
    <>
      <button
        className={styles.contact_btn}
        onClick={() => setOpenContactUs(true)}
      >
        <ContactIcon />
      </button>
      <DialogWrapper open={openContactUs} setOpen={setOpenContactUs}>
        <ContactUs closeForm={() => setOpenContactUs(false)} />
      </DialogWrapper>
    </>
  );
};

export default ContactUsFloating;
