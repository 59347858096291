import React from 'react';

const SadeemWhiteLogo = () => {
  return (
    <svg
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      //   style=" 0 0 512 512;"
      fill="#fff"
      width="64"
      height="64"
      xmlSpace="preserve"
    >
      <path
        id="logo-shape"
        className="st0"
        d="M256,0C114.6,0,0,114.6,0,256c0,90.1,46.5,169.3,116.8,214.9V255.3c0-17.9,14.5-32.4,32.4-32.4
 s32.4,14.5,32.4,32.4v243.8c0,0.6-0.1,1.2-0.1,1.8c23.6,7.2,48.6,11,74.5,11c141.4,0,256-114.6,256-256S397.4,0,256,0z M288.4,321.5
 c0,17.9-14.5,32.4-32.4,32.4c-17.9,0-32.4-14.5-32.4-32.4V189.1c0-17.9,14.5-32.4,32.4-32.4c17.9,0,32.4,14.5,32.4,32.4V321.5z
  M395.3,255.3c0,17.9-14.5,32.4-32.4,32.4c-17.9,0-32.4-14.5-32.4-32.4V122.8c0-17.9,14.5-32.4,32.4-32.4
 c17.9,0,32.4,14.5,32.4,32.4V255.3z"
      />
    </svg>
  );
};

export default SadeemWhiteLogo;
